@mixin xs {
  @media (max-width: $xs) {
    @content;
  }
}

@mixin s {
  @media (max-width: $s) {
    @content;
  }
}

@mixin sm {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin md {
  @media (max-width: $md) {
    @content;
  }
}

@mixin lg {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin xl {
  @media (max-width: $xl) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: $xxl) {
    @content;
  }
}