@import "./mixins.scss";
@import "./constants.scss";

@font-face {
  font-family: "Soehne Breit Kraftig";
  src: url("../fonts/soehne-breit-kraftig.woff2") format("woff2"),
       url("../fonts/soehne-breit-kraftig.woff") format("woff"),
       url("../fonts/soehne-breit-kraftig.ttf") format("truetype");
}

@font-face {
  font-family: "Soehne Breit Halbfett";
  src: url("../fonts/soehne-breit-halbfett.woff2") format("woff2"),
       url("../fonts/soehne-breit-halbfett.woff") format("woff"),
       url("../fonts/soehne-breit-halbfett.ttf") format("truetype");
}

@font-face {
  font-family: "Soehne Breit Fett";
  src: url("../fonts/soehne-breit-fett.woff2") format("woff2"),
       url("../fonts/soehne-breit-fett.woff") format("woff"),
       url("../fonts/soehne-breit-fett.ttf") format("truetype");
}

@font-face {
  font-family: "Suisse BP Int'l Bold";
  src: url("../fonts/Suisse\ BP\ Int\'l\ Bold.woff") format("woff");
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}

body {
  font-family: 'Soehne Breit Kraftig', -apple-system, sans-serif;
  letter-spacing: -0.02rem;
  line-height: 160%;
}

h1 {
  font-size: 1.75rem;
  line-height: 100%;

  @include md {
    font-size: 1.35rem;
  }
}

h2 {
  font-family: "Soehne Breit Fett", "Suisse BP Int'l Bold", -apple-system, sans-serif;

  @include xs {
    font-size: 4.45vw;
  }
}

p {
  font-family: "Soehne Breit Kraftig", "Suisse BP Int'l Bold", -apple-system, sans-serif;
}

a {
  font-family: "Soehne Breit Halbfett";
}

.nav__list * {
  @include xs {
    font-size: 3.8vw;
  }
}

.item__title {
  @include s {
    font-size: 3.85vw;
  }
}

.work__details {
  font-family: "Soehne Breit Halbfett", "Suisse BP Int'l Bold", -apple-system, sans-serif;
  font-size: 0.8rem;
  line-height: 140%;

  @include xs {
    font-size: 0.7rem;
    line-height: 120%;
  }
}

.dropcap--work::first-letter {
  font-size: 2.3rem;
}

.dropcap--about::first-letter {
  font-size: 3.2rem;
}