// Breakpoints
$xs: 360px;
$s: 420px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;

// Spacers
$base: 1rem;
$spacer1: $base;
$spacer2: $base * 2;
$spacer3: $base * 3;
$spacer4: $base * 4;

// Colors
$default-text-color: #FFFFFF;
$default-background-color: #111111;
$grey: #8C8C8C;

// Other
$timing: 0.3s;